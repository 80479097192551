.login-page-box{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: url("../../../assets/login-bg.png") no-repeat;
  background-size: 100% 100%;

  .login-box{
    display: block;
    width: 25rem;
    height: 25rem;
    background: #FFFFFF;
    border-radius: 5px;
    justify-content: center;
    margin-top: -100px;

    .logo-box{
      display: flex;
      width: auto;
      margin-top: 20px;
      justify-content: center;

      .logo-img{
        width: 38px;
        height: 38px;
      }

      .logo-name{
        display: block;
        width: 85px;

        & > span:nth-child(1){
          display: block;
          height: 20px;
          line-height: 20px;
          font-size: 18px;
          font-weight: 500;
          color: #3A3B3F;
        }

        & > span:nth-child(2){
          display: block;
          height: 12px;
          line-height: 12px;
          font-size: 14px;
          font-weight: 400;
          color: #8C8C8C;
          margin-top: 5px;
        }
      }
    }

    .login-input{
      display: block;
      width: auto;
      margin: 25px 30px 0;

      & > input{
        display: block;
        width: calc(100% - 16px);
        padding: 0 8px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        margin: 0;
        font-size: 16px;
        outline: none;
      }

      & > input:nth-child(2){
        margin-top: 15px;
      }
    }

    .login-btn{
      display: block;
      width: auto;
      margin: 28px 30px 0;

      & > span{
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #363C4B;
        border-radius: 5px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: #ECECEC;
      }
    }
  }
}

input{
  -webkit-appearance: none;
}