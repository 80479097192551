@font-face {
  font-family: 'OxaniumRegular';
  src: url('../../../assets/fonts/OXANIUM-MEDIUM.TTF');
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

.main {
  display: block;
  overflow: hidden;
  width: 100%;

  .message-tips{
    display: flex;
    position: fixed;
    z-index: 1000;
    justify-content: center;
    width: 100%;
    top: 50px;

    span{
      display: inline-block;
      width: auto;
      color: #222;
      height: 30px;
      line-height: 30px;
      text-align: center;
      box-sizing: border-box;
      font-size: 14px;
      border-radius: 5px;
      padding: 0 50px;
      background: rgba(244,199,51, .8);
    }
}

.small-map{
width: 100%;
height: 100%;
position: fixed;
background-color: rgba(0,0,0,0.70);
right: 0;
top: 0;
bottom: 0;
z-index: 3;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
visibility: hidden;
opacity: 0;

img:nth-child{
  width: 40%;
}

.small-block-map{
  position: fixed;
  width: 60%;
  display: flex;
  justify-content: center;
  //align-items: center;
  overflow: hidden;

  .small-block-map-img-box{
    display: block;
    width: calc(100vh / 100 * 60);

    img{
      width: 100%;
    }
  }

  .small-block-map-img-content{
    display: flex;
    width: auto;
    height: auto;
    margin-left: 1rem;
    margin-top: 2rem;
    justify-content: space-between;

    & > div:nth-child(1){
      width: 70%;

      .small-block-map-img-content-header{
        display: block;
        width: auto;
        background: url("../../../assets/area-sell-icon.png") left center no-repeat;
        background-size: 0.83rem 0.58rem;
        color: #ffffff;
        height: 1.92rem;
        line-height: 1.92rem;
        font-size: 1.17rem;
        font-family: OxaniumRegular;
        font-weight: 500;
        text-align: right;
        padding-left: 1.5rem;
      }

      .small-block-map-img-content-line{
        display: block;
        width: 100%;
        height: 1px;
        background: url("../../../assets/area-sell-line-icon.png") center no-repeat;
        background-size: 100% 100%;
        margin-top: 0.25rem;
      }

      .small-block-map-img-content-list{
        display: block;
        width: 100%;
        height: 30vh;
        padding-top: 0.42rem;
        overflow-y: scroll;

        & > span{
          display: block;
          width: 100%;
          height: 1.6rem;
          line-height: 1.6rem;
          text-align: right;
          font-size: 1rem;
          font-family: OxaniumRegular;
          font-weight: 500;
          color: #F4C733;
          margin-top: 0.42rem;
          cursor: pointer;
        }

        .y-sale-status{
          color: #54ed48;
        }
      }
    }

    .small-block-map-img-content-btn{
      width: auto;
      margin-left: 1rem;

      span{
        display: block;
        width: 4.2rem;
        height: 2.5rem;
        line-height: 2.5rem;
        cursor: pointer;
        background: url("../../../assets/order-cancel-btn.png") no-repeat;
        background-size: 100% 100%;
        font-size: 1.25rem;
        font-weight: 400;
        color: #DDDDDD;
      }
    }
  }
}

.small-block-map-visible{
  -webkit-animation-name: block-visible;
          animation-name: block-visible; /*动画名称*/
      animation-duration: .3s; /*动画持续时间*/
      animation-iteration-count: 1; /*动画次数*/
      animation-delay: 0s; /*延迟时间*/
      -webkit-animation-name: webkit-block-visible; /*动画名称*/
      -webkit-animation-duration: .3s; /*动画持续时间*/
      -webkit-animation-iteration-count: 1; /*动画次数*/
      -webkit-animation-delay: 0s; /*延迟时间*/
    }

    @-webkit-keyframes block-visible{
      0%{height: 0}
      5%{height: calc(100vh / 100 * 3)}
      10%{height: calc(100vh / 100 * 6)}
      15%{height: calc(100vh / 100 * 9)}
      20%{height: calc(100vh / 100 * 12)}
      25%{height: calc(100vh / 100 * 15)}
      30%{height: calc(100vh / 100 * 18)}
      35%{height: calc(100vh / 100 * 21)}
      40%{height: calc(100vh / 100 * 24)}
      45%{height: calc(100vh / 100 * 27)}
      50%{height: calc(100vh / 100 * 30)}
      55%{height: calc(100vh / 100 * 33)}
      60%{height: calc(100vh / 100 * 36)}
      65%{height: calc(100vh / 100 * 39)}
      70%{height: calc(100vh / 100 * 42)}
      75%{height: calc(100vh / 100 * 45)}
      80%{height: calc(100vh / 100 * 48)}
      85%{height: calc(100vh / 100 * 51)}
      90%{height: calc(100vh / 100 * 54)}
      95%{height: calc(100vh / 100 * 57)}
      100%{height: calc(100vh / 100 * 60)}
    }

    @keyframes block-visible{
      0%{height: 0}
      5%{height: calc(100vh / 100 * 3)}
      10%{height: calc(100vh / 100 * 6)}
      15%{height: calc(100vh / 100 * 9)}
      20%{height: calc(100vh / 100 * 12)}
      25%{height: calc(100vh / 100 * 15)}
      30%{height: calc(100vh / 100 * 18)}
      35%{height: calc(100vh / 100 * 21)}
      40%{height: calc(100vh / 100 * 24)}
      45%{height: calc(100vh / 100 * 27)}
      50%{height: calc(100vh / 100 * 30)}
      55%{height: calc(100vh / 100 * 33)}
      60%{height: calc(100vh / 100 * 36)}
      65%{height: calc(100vh / 100 * 39)}
      70%{height: calc(100vh / 100 * 42)}
      75%{height: calc(100vh / 100 * 45)}
      80%{height: calc(100vh / 100 * 48)}
      85%{height: calc(100vh / 100 * 51)}
      90%{height: calc(100vh / 100 * 54)}
      95%{height: calc(100vh / 100 * 57)}
      100%{height: calc(100vh / 100 * 60)}
    }

    @-webkit-keyframes webkit-block-visible{
      0%{height: 0}
      5%{height: calc(100vh / 100 * 3)}
      10%{height: calc(100vh / 100 * 6)}
      15%{height: calc(100vh / 100 * 9)}
      20%{height: calc(100vh / 100 * 12)}
      25%{height: calc(100vh / 100 * 15)}
      30%{height: calc(100vh / 100 * 18)}
      35%{height: calc(100vh / 100 * 21)}
      40%{height: calc(100vh / 100 * 24)}
      45%{height: calc(100vh / 100 * 27)}
      50%{height: calc(100vh / 100 * 30)}
      55%{height: calc(100vh / 100 * 33)}
      60%{height: calc(100vh / 100 * 36)}
      65%{height: calc(100vh / 100 * 39)}
      70%{height: calc(100vh / 100 * 42)}
      75%{height: calc(100vh / 100 * 45)}
      80%{height: calc(100vh / 100 * 48)}
      85%{height: calc(100vh / 100 * 51)}
      90%{height: calc(100vh / 100 * 54)}
      95%{height: calc(100vh / 100 * 57)}
      100%{height: calc(100vh / 100 * 60)}
    }

    @keyframes webkit-block-visible{
      0%{height: 0}
      5%{height: calc(100vh / 100 * 3)}
      10%{height: calc(100vh / 100 * 6)}
      15%{height: calc(100vh / 100 * 9)}
      20%{height: calc(100vh / 100 * 12)}
      25%{height: calc(100vh / 100 * 15)}
      30%{height: calc(100vh / 100 * 18)}
      35%{height: calc(100vh / 100 * 21)}
      40%{height: calc(100vh / 100 * 24)}
      45%{height: calc(100vh / 100 * 27)}
      50%{height: calc(100vh / 100 * 30)}
      55%{height: calc(100vh / 100 * 33)}
      60%{height: calc(100vh / 100 * 36)}
      65%{height: calc(100vh / 100 * 39)}
      70%{height: calc(100vh / 100 * 42)}
      75%{height: calc(100vh / 100 * 45)}
      80%{height: calc(100vh / 100 * 48)}
      85%{height: calc(100vh / 100 * 51)}
      90%{height: calc(100vh / 100 * 54)}
      95%{height: calc(100vh / 100 * 57)}
      100%{height: calc(100vh / 100 * 60)}
    }
  }

  /*定义动画*/
  @-webkit-keyframes map-visible {
    0% {opacity: 0;}
    10% {opacity: 0.1;}
    15% {opacity: 0.15;}
    20% {opacity: 0.2;}
    25% {opacity: 0.25;}
    30% {opacity: 0.3;}
    35% {opacity: 0.35;}
    40% {opacity: 0.4;}
    45% {opacity: 0.45;}
    50% {opacity: 0.5;}
    55% {opacity: 0.55;}
    60% {opacity: 0.6;}
    65% {opacity: 0.65;}
    70% {opacity: 0.7;}
    75% {opacity: 0.75;}
    80% {opacity: 0.8;}
    85% {opacity: 0.85;}
    90% {opacity: 0.9;}
    95% {opacity: 0.95;}
    100% {opacity: 1;}
  }
  @keyframes map-visible {
    0% {opacity: 0;}
    10% {opacity: 0.1;}
    15% {opacity: 0.15;}
    20% {opacity: 0.2;}
    25% {opacity: 0.25;}
    30% {opacity: 0.3;}
    35% {opacity: 0.35;}
    40% {opacity: 0.4;}
    45% {opacity: 0.45;}
    50% {opacity: 0.5;}
    55% {opacity: 0.55;}
    60% {opacity: 0.6;}
    65% {opacity: 0.65;}
    70% {opacity: 0.7;}
    75% {opacity: 0.75;}
    80% {opacity: 0.8;}
    85% {opacity: 0.85;}
    90% {opacity: 0.9;}
    95% {opacity: 0.95;}
    100% {opacity: 1;}
  }

  /*定义动画*/
  @-webkit-keyframes webkit-map-visible {
    0% {opacity: 0;}
    10% {opacity: 0.1;}
    15% {opacity: 0.15;}
    20% {opacity: 0.2;}
    25% {opacity: 0.25;}
    30% {opacity: 0.3;}
    35% {opacity: 0.35;}
    40% {opacity: 0.4;}
    45% {opacity: 0.45;}
    50% {opacity: 0.5;}
    55% {opacity: 0.55;}
    60% {opacity: 0.6;}
    65% {opacity: 0.65;}
    70% {opacity: 0.7;}
    75% {opacity: 0.75;}
    80% {opacity: 0.8;}
    85% {opacity: 0.85;}
    90% {opacity: 0.9;}
    95% {opacity: 0.95;}
    100% {opacity: 1;}
  }

  /*定义动画*/
  @-webkit-keyframes map-hidden {
    0% {opacity: 1;}
    10% {opacity: 0.95;}
    15% {opacity: 0.9;}
    20% {opacity: 0.85;}
    25% {opacity: 0.8;}
    30% {opacity: 0.75;}
    35% {opacity: 0.7;}
    40% {opacity: 0.65;}
    45% {opacity: 0.6;}
    50% {opacity: 0.55;}
    55% {opacity: 0.5;}
    60% {opacity: 0.45;}
    65% {opacity: 0.4;}
    70% {opacity: 0.35;}
    75% {opacity: 0.3;}
    80% {opacity: 0.25;}
    85% {opacity: 0.2;}
    90% {opacity: 0.15;}
    95% {opacity: 0.1;}
    100% {opacity: 0;}
  }
  @keyframes map-hidden {
    0% {opacity: 1;}
    10% {opacity: 0.95;}
    15% {opacity: 0.9;}
    20% {opacity: 0.85;}
    25% {opacity: 0.8;}
    30% {opacity: 0.75;}
    35% {opacity: 0.7;}
    40% {opacity: 0.65;}
    45% {opacity: 0.6;}
    50% {opacity: 0.55;}
    55% {opacity: 0.5;}
    60% {opacity: 0.45;}
    65% {opacity: 0.4;}
    70% {opacity: 0.35;}
    75% {opacity: 0.3;}
    80% {opacity: 0.25;}
    85% {opacity: 0.2;}
    90% {opacity: 0.15;}
    95% {opacity: 0.1;}
    100% {opacity: 0;}
  }

  /*定义动画*/
  @-webkit-keyframes webkit-map-hidden {
    0% {opacity: 1;}
    10% {opacity: 0.95;}
    15% {opacity: 0.9;}
    20% {opacity: 0.85;}
    25% {opacity: 0.8;}
    30% {opacity: 0.75;}
    35% {opacity: 0.7;}
    40% {opacity: 0.65;}
    45% {opacity: 0.6;}
    50% {opacity: 0.55;}
    55% {opacity: 0.5;}
    60% {opacity: 0.45;}
    65% {opacity: 0.4;}
    70% {opacity: 0.35;}
    75% {opacity: 0.3;}
    80% {opacity: 0.25;}
    85% {opacity: 0.2;}
    90% {opacity: 0.15;}
    95% {opacity: 0.1;}
    100% {opacity: 0;}
  }

  /*使用动画：将动画绑定元素上*/
  .small-map-display {
    -webkit-animation-name: map-visible;
            animation-name: map-visible; /*动画名称*/
    animation-duration: .5s; /*动画持续时间*/
    animation-iteration-count: 1; /*动画次数*/
    animation-delay: 0s; /*延迟时间*/
    -webkit-animation-name: webkit-map-visible; /*动画名称*/
    -webkit-animation-duration: .5s; /*动画持续时间*/
    -webkit-animation-iteration-count: 1; /*动画次数*/
    -webkit-animation-delay: 0s; /*延迟时间*/
    visibility: visible;
    opacity: 1;
  }

  .small-map-hidden {
    -webkit-animation-name: map-hidden;
            animation-name: map-hidden; /*动画名称*/
    animation-duration: .5s; /*动画持续时间*/
    animation-iteration-count: 1; /*动画次数*/
    animation-delay: 0s; /*延迟时间*/
    -webkit-animation-name: webkit-map-hidden; /*动画名称*/
    -webkit-animation-duration: .5s; /*动画持续时间*/
    -webkit-animation-iteration-count: 1; /*动画次数*/
    -webkit-animation-delay: 0s; /*延迟时间*/
    visibility: visible;
  }


  .landscape-tip{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999999999999;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,1);
    position: relative;

    .logo-box{
      position: absolute;
      top: 10rem;

      img {
        width: 40%;
      }
    }

    .landscape-tip-box{
      margin-top: -6rem;

      img{
        width: 38%;
      }

      span{
        font-size: 1.25rem;
        display: block;
        text-align: center;
      }
    }
  }


  .map-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    //background: url("../../../assets/background-image.png") no-repeat;
    background-color: black;
    //position: fixed;
    //top: 0;
    //left: 0;
    position: relative;
    overflow: hidden;

    .header-main {
      width: calc(100% - 2.75rem * 2);
      padding: 0 2.75rem;
      height: 4.42rem;
      background: url("../../../assets/header_bg.png") no-repeat;
      background-size: 100% 100%;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      //position: fixed;
      position: absolute;
      top: 0;

      div{
        display: flex;
        width: 100%;
        height: 4.25rem;
        align-items: center;
        justify-content: flex-end;

        img:nth-child(1){
          height: 35%;
        }

        .user-info{
          display: flex;

          & > span{
            font-size: 1.17rem;
            font-weight: 400;
            color: #DEDEDE;
          }

          & > span:nth-child(2){
            margin: 0 0.83rem;
          }

          & > span:nth-child(3){
            cursor: pointer;
          }
        }
      }


      img:nth-child(2){
        width: 100%;
        height: 0.17rem;
      }
    }

    .bottom-main {
      //position: fixed;
      position: absolute;
      bottom: 0;
      width: calc(100% - 2.75rem * 2);
      padding: 0 2.75rem;
      height: 5.42rem;
      z-index: 2;

      .map-switch{
        display: flex;
        width: 100%;
        height: 3.5rem;
        justify-content: flex-end;

        button{
          width: 10.2rem;
          height: 2.75rem;
          line-height: 2.75rem;
          padding: 0;
          margin-left: 0.83rem;
          border: none;
          background: url("../../../assets/select_btn.png") no-repeat;
          background-size: 100% 100%;
          color: #ffffff;
          cursor: pointer;
        }

        .selected-map-type{
          background: url("../../../assets/selected_btn.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      img:nth-child(2){
        width: 100%;
        height: 0.17rem;
      }
    }

    .left-main {
      //position: fixed;
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 100%;
      background: url("../../../assets/left_bg.png") no-repeat;
      background-size: 100% 100%;
      z-index: 1;
      display: flex;

      .area-list{
        display: block;
        overflow: hidden;

        .pack-up-icon{
          display: block;
          width: 3.33rem;
          height: 3.33rem;
          margin-left: 2.75rem;
          margin-top: 7.5rem;

          img{
            width: 100%;
            height: 100%;
          }
        }

        .area-block-list{
          display: block;
          width: calc(100% - 2.75rem);
          height: 50%;
          padding-left: 2.75rem;
          margin-top: 45%;
          position: relative;
          padding-bottom: 0.58rem;
          overflow: hidden;

          .area-block-list-header{
            display: block;
            width: 100%;
            height: 2.5rem;
            background: url("../../../assets/area-block-list-header-bg.png") no-repeat;
            background-size: 100% 100%;
            position: relative;
            top: 0;

            .area-block-list-name{
              display: inline-block;
              width: auto;
              height: 2.5rem;
              line-height: 2.5rem;
              position: absolute;
              left: 0.33rem;
              font-size: 1.08rem;
              font-weight: 500;
              bottom: -0.20rem;
            }

            .area-block-list-back{
              display: block;
              width: 2.5rem;
              height: 2.5rem;
              background: url("../../../assets/back_btn.png") right no-repeat;
              background-position-x: 1rem;
              background-position-y: 1rem;
              background-size: 0.5rem 0.83rem;
              position: absolute;
              right: 0;
            }
          }

          b{
            display: block;
            width: 100%;
            height: 0.5rem;
            background: url("../../../assets/area-block-list-footer-bg.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            bottom: 0;
          }

          .area-block-list-main{
            display: block;
            width: 100%;
            overflow-y: scroll;
            height: calc(100% - 2.5rem);
            background-color: rgba(0,0,0,.6);
            position: relative;
            overflow-x: hidden;
            top: 0;

            .area-block-list-item{
              display: flex;
              width: calc(100% - 0.63rem - 0.54rem - 1rem);
              height: 2.75rem;
              background: url("../../../assets/area-block-select-btn-icon.png") no-repeat;
              background-size: 100% 100%;
              margin-left: 0.63rem;
              margin-top: 0.54rem;
              align-items: center;
              padding-right: 1rem;
              cursor: pointer;

              img{
                width: 2.25rem;
                height: 1.92rem;
              }

              span{
                border: none;
                padding: 0;
                background-color: initial;
                font-weight: 300;
                color: #D9D9D9;
                line-height: 2.75rem;
                font-size: 1.1rem;
                width: 100%;
                text-align: left;
                height: 2.75rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .area-block-active{
              background: url("../../../assets/area-block-selected-btn-icon.png") no-repeat;
              background-size: 100% 100%;
              margin-left: 1.25rem;
            }
          }
        }
      }
    }

    .right-main {
      //position: fixed;
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background: url("../../../assets/right_bg.png") no-repeat;
      background-size: 100% 100%;
      z-index: 1;
      display: flex;
      justify-content: flex-end;

      .land-block-list{
        display: block;
        width: 10.83rem;
        height: auto;
        margin-top: 45%;
        position: relative;
        right: 0;

        .land-block-list-header{
          display: block;
          width: 10.83rem;
          height:1.83rem;
          line-height: 1.5rem;
          background: url("../../../assets/land-block-list-header-bg.jpg") no-repeat;
          background-size: 100% 100%;
          font-size: 1rem;
          font-weight: 300;
          color: #D9D9D9;
          text-align: left;
          text-indent: 1rem;
          position: absolute;
          right: 0;
        }

        .land-block-list-main{
          display: block;
          width: 7.5rem;
          height: 45%;
          overflow-y: scroll;
          padding-left: 0.5rem;
          position: absolute;
          top: 1.5rem;
          margin-top: 0.5rem;


          .land-block-list-item{
            display: block;
            width: 7.5rem;
            height: 2.08rem;
            line-height: 2.17rem;
            border: none;
            font-size: 1rem;
            font-weight: 300;
            color: #D9D9D9;
            background: url("../../../assets/land-block-select-btn-bg.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 0.58rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }

          .land-block-list-item-active{
            background: url("../../../assets/land-block-selected-btn-bg.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .right-main-display{
      -webkit-animation-name: area-block-visible;
              animation-name: area-block-visible; /*动画名称*/
      animation-duration: .4s; /*动画持续时间*/
      animation-iteration-count: 1; /*动画次数*/
      animation-delay: 0s; /*延迟时间*/
      -webkit-animation-name: webkit-area-block-visible; /*动画名称*/
      -webkit-animation-duration: .4s; /*动画持续时间*/
      -webkit-animation-iteration-count: 1; /*动画次数*/
      -webkit-animation-delay: 0s; /*延迟时间*/
    }

    @-webkit-keyframes area-block-visible {
      0% {right: -10.83rem;}
      5% {right: -10.58rem;}
      10% {right: -10.36rem;}
      15% {right: -9.84rem;}
      20% {right: -9.32rem;}
      25% {right: -8.80rem;}
      30% {right: -8.28rem;}
      35% {right: -7.76rem;}
      40% {right: -7.24rem;}
      45% {right: -6.72rem;}
      50% {right: -6.20rem;}
      55% {right: -5.68rem;}
      60% {right: -5.16rem;}
      65% {right: -4.64rem;}
      70% {right: -4.12rem;}
      75% {right: -3.60rem;}
      80% {right: -2.08rem;}
      85% {right: -1.56rem;}
      90% {right: -1.04rem;}
      95% {right: -0.52rem;}
      100% {right:-0;}
    }

    @keyframes area-block-visible {
      0% {right: -10.83rem;}
      5% {right: -10.58rem;}
      10% {right: -10.36rem;}
      15% {right: -9.84rem;}
      20% {right: -9.32rem;}
      25% {right: -8.80rem;}
      30% {right: -8.28rem;}
      35% {right: -7.76rem;}
      40% {right: -7.24rem;}
      45% {right: -6.72rem;}
      50% {right: -6.20rem;}
      55% {right: -5.68rem;}
      60% {right: -5.16rem;}
      65% {right: -4.64rem;}
      70% {right: -4.12rem;}
      75% {right: -3.60rem;}
      80% {right: -2.08rem;}
      85% {right: -1.56rem;}
      90% {right: -1.04rem;}
      95% {right: -0.52rem;}
      100% {right:-0;}
    }

    @-webkit-keyframes webkit-area-block-visible {
      0% {right: -10.83rem;}
      5% {right: -10.58rem;}
      10% {right: -10.36rem;}
      15% {right: -9.84rem;}
      20% {right: -9.32rem;}
      25% {right: -8.80rem;}
      30% {right: -8.28rem;}
      35% {right: -7.76rem;}
      40% {right: -7.24rem;}
      45% {right: -6.72rem;}
      50% {right: -6.20rem;}
      55% {right: -5.68rem;}
      60% {right: -5.16rem;}
      65% {right: -4.64rem;}
      70% {right: -4.12rem;}
      75% {right: -3.60rem;}
      80% {right: -2.08rem;}
      85% {right: -1.56rem;}
      90% {right: -1.04rem;}
      95% {right: -0.52rem;}
      100% {right:-0;}
    }

    @keyframes webkit-area-block-visible {
      0% {right: -10.83rem;}
      5% {right: -10.58rem;}
      10% {right: -10.36rem;}
      15% {right: -9.84rem;}
      20% {right: -9.32rem;}
      25% {right: -8.80rem;}
      30% {right: -8.28rem;}
      35% {right: -7.76rem;}
      40% {right: -7.24rem;}
      45% {right: -6.72rem;}
      50% {right: -6.20rem;}
      55% {right: -5.68rem;}
      60% {right: -5.16rem;}
      65% {right: -4.64rem;}
      70% {right: -4.12rem;}
      75% {right: -3.60rem;}
      80% {right: -2.08rem;}
      85% {right: -1.56rem;}
      90% {right: -1.04rem;}
      95% {right: -0.52rem;}
      100% {right:-0;}
    }

    .map-container-main {
      height: 0;
    }

    .viewer-container {
      height: 100%;
    }
  }

  .order-input-box{
    position: fixed;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #000000;
    border: 1px solid #808080;
    width: 45vw;
    height: 70%;
    z-index: 1000;
    display: block;
    overflow: hidden;

    .order-input-main{
      height: 100%;

      .order-input-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        height: 3.08rem;
        line-height: 3.08rem;
        background: url("../../../assets/edit-header-bg.png") 0.75rem 0.75rem no-repeat;
        background-size: 100% 100%;
        padding-top: 0.75rem;
        padding-left: 0.75rem;

        & > b{
          font-size: 1.25rem;
          font-weight: 400;
          color: #DEDEDE;
          background: linear-gradient(0deg, #ABABAB 0%, #F0F0F0 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 1.5rem;
        }

        & > span{
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url("../../../assets/order_close.png") no-repeat;
          background-size: 100% 100%;
          margin-right: 1.75rem;
          cursor: pointer;
        }
      }

      .order-input-form-box{
        height: 100%;
        overflow-y: scroll;

        .order-input-form{
          display: block;
          width: calc(100% - 3.5rem);
          height: 100%;
          margin: 1.67rem 1.75rem 0;

          .order-form-line{
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(255, 255, 255, .3);
            margin-top: 1.08rem;

            & > span{
              display: block;
              width: 0.5rem;
              height: 1px;
              background-color: rgba(255, 255, 255, .8);
            }
          }

          .order-zone-size-number{
            margin-top: 0.83rem;
          }

          .order-position-name-number,
          .order-zone-size-number{
            display: flex;
            width: auto;
            justify-content: space-between;

            .order-position-name,
            .order-position-number,
            .order-zone-size,
            .order-zone-number{
              & > span{
                display: block;
                width: auto;
                height: 2.33rem;
                line-height: 2.33rem;
                font-size: 1.25rem;
                font-weight: 400;
                color: #B0B0B0;
                background: linear-gradient(0deg, #BBBBBB 0%, #FFFFFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-right: 0.83rem;
                text-align: left;
              }
            }

            .order-position-number,
            .order-zone-number{
              & > span{
                width: 3.33rem;
              }
            }

            .order-position-name,
            .order-zone-size{
              display: flex;
              width: 55%;
              height: auto;
              justify-content: flex-start;

              & > div{
                display: block;
                width: 50%;
                height: 2.17rem;
                line-height: 2.17rem;
                background: #000000;
                border: 1px solid rgba(158, 158, 158, .5);
                font-size: 1.17rem;
                font-family: OxaniumRegular;
                font-weight: 500;
                color: #FFFFFF;
                text-align: center;
              }
            }

            .order-position-number,
            .order-zone-number{
              display: flex;
              width: 42%;
              height: auto;
              justify-content: flex-end;

              & > div{
                display: block;
                width: 8.33rem;
                position: relative;

                & > b{
                  display: flex;
                  width: calc(100% - 2px);
                  height: calc(100% - 2px);
                  background: #000000;
                  border: 1px solid rgba(158, 158, 158, .5);
                  justify-content: flex-end;
                  align-items: center;

                  & > i{
                    display: block;
                    width: 2.17rem;
                    height: 2.17rem;
                    background: url("../../../assets/down-arrow-icon.png") center no-repeat #303030;
                    background-size: 14px 7px;

                  }
                }

                & > select{
                  position: absolute;
                  width: 100%;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto 0;
                  padding-left: 0.83rem;
                  height: 2.17rem;
                  line-height: 2.17rem;
                  color: #FFFFFF;
                  font-weight: 500;
                  font-size: 1rem;
                  appearance: none;
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  border-radius: initial;
                  background: rgba(0,0,0,0);
                  border: none;
                  outline: none;
                  z-index: 10;
                }
              }
            }
          }

          .order-zone-type,
          .order-sale-type,
          .order-sale-price{
            display: flex;
            justify-content: left;
            align-items: center;

            & > span{
              display: inline-block;
              height: 2.5rem;
              line-height: 2.5rem;
              font-size: 1.25rem;
              font-weight: 400;
              color: #B0B0B0;
              background: linear-gradient(0deg, #BBBBBB 0%, #FFFFFF 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: left;
              margin-right: 3.33rem;
            }
          }

          .order-zone-type,
          .order-sale-type,
          .order-sale-price{
            margin-top: 0.5rem;

            .order-zone-type-item,
            .order-sale-type-item,
            .order-sale-checkbox{
              display: flex;
              align-items: center;
              height: 2.5rem;
              line-height: 2.5rem;
              font-size: 1.17rem;
              font-weight: 400;
              color: #FFFFFF;
              margin-right: 1.25rem;
            }

            .order-zone-type-item::after,
            .order-sale-type-item::after,
            .order-sale-checkbox::after{
              display: inline-block;
              content: ' ';
              width: 10px;
              height: 10px;
              border: 2px solid #D1D0D0;
              margin-left: 5px;
            }

            .checked-status::after{
              display: inline-block;
              content: ' ';
              width: 10px;
              height: 10px;
              border: 2px solid #f59f29;
              margin-left: 5px;
              background-color: #f59f29;
            }
          }

          .order-sale-type{
            margin-top: 0.5rem;
          }

          .order-sale-price{
            height: 2.333rem;
            line-height: 2.333rem;

            .order-sale-input{
              width: 8.333rem;
              height: 2.17rem;
              line-height: 2.17rem;
              background: #000000;
              border: 1px solid rgba(158, 158, 158, .5);
              margin-right: 1.67rem;
              color: #FFFFFF;
              text-align: center;
            }
          }

          .order-customer-info,
          .order-customer-phone {
            margin-top: 1.42rem;

            & > span{
              display: block;
              width: 100%;
              height: 1.58rem;
              line-height: 1.58rem;
              font-size: 1.5rem;
              font-weight: 400;
              color: #B0B0B0;
              background: linear-gradient(0deg, #BBBBBB 0%, #FFFFFF 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: left;
            }
          }

          .order-customer-info{
            & > textarea{
              width: calc(100% - 2px - 1.67rem);
              height: 4.92rem;
              background: #000000;
              border: 1px solid rgba(158, 158, 158, .5);
              margin: 0.83rem 0 0 0;
              padding: 0.83rem;
              resize: none;
              font-family: OxaniumRegular;
              font-weight: 500;
              color: #FFFFFF;
            }
          }

          .order-customer-phone{
            & > input{
              display: block;
              width: calc(100% - 2px - 1.67rem);
              height: 2.333rem;
              background: #000000;
              border: 1px solid rgba(158, 158, 158, .5);
              padding: 0 0.83rem;
              margin: 0.83rem 0 0 0;
              outline: none;
              font-family: OxaniumRegular;
              font-weight: 500;
              color: #FFFFFF;
            }
          }

          .order-input-submit{
            width: 100%;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2.5rem;

            .order-input-del{
              display: flex;
              height: 3rem;
              align-items: center;

              & > span{
                display: inline-block;
                width: 2.5rem;
                height: 2.5rem;
                background: url("../../../assets/order_delete.png") no-repeat;
                background-size: 100% 100%;
              }
            }

            .order-input-btn{
              display: flex;
              width: 100%;
              height: 3rem;
              justify-content: flex-end;

              & > span{
                display: inline-block;
                width: 35%;
                height: 3rem;
                line-height: 3rem;
                font-size: 1.5rem;
                font-weight: 500;
              }

              & > span:nth-child(1){
                color: #FFEFC9;
                background: url("../../../assets/order-cancel-btn.png") no-repeat;
                background-size: 100% 100%;
              }
              & > span:nth-child(2){
                color: #FFEFC9;
                background: url("../../../assets/order-submit-btn.png") no-repeat;
                background-size: 100% 100%;
                margin-left: 2rem;
              }
            }
          }

          .order-null-box{
            height: 6.67rem;
          }
        }
      }
    }
  }
}

.area-block-list-main::-webkit-scrollbar,
.land-block-list-main::-webkit-scrollbar{
  display: none;
}

*::-webkit-scrollbar{
  display: none;
}

.map-container::-webkit-scrollbar{
  display: none;
}

input, input:focus,
textarea, textarea:focus,
select, select:focus{
  font-size: 16px !important;
  //-webkit-appearance: none;
  outline: none;
}


body {
  -webkit-user-select: none;
  user-select: none;
}
